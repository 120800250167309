<template>
  <div>
    <div
      id="impress"
      class="container container-onepage"
    >
      <h2>Impressum</h2>
      <h4>Inhaltlich verantwortlich nach § 6 MDStV:</h4>
      <p>
        Gesellschaft für Interkulturelles Zusammenleben gGmbH.<br>
        Ansprechpartnerin: Dr. Britta Marschke<br>
      </p>

      <h4>Anschrift:</h4>
      <p>
        Gesellschaft für Interkulturelles Zusammenleben gGmbH.<br>
        Reformationsplatz 2<br>
        13597 Berlin<br>
        <br>
        Tel: <a href="tel:+4930513010000">
          +49 30 513 0 100 00
        </a><br>
      </p>

      <h4>Hinweise:</h4>
      <p>
        Rechtliche Hinweise entnehmen Sie bitte dem Haftungsausschluss weiter unten auf der Seite.<br>
        Um uns zu kontaktieren, nutzen Sie bitte das Kontaktformular unter <a href="https://giz.berlin/contact/main/form/4">
          giz.berlin
        </a>.
      </p>
    </div>

    <div
      class="container-fluid"
      style="background-color: #EFEFEF;"
    >
      <div
        id="fund"
        class="container container-onepage"
      >
        <h3>Gefördert durch:</h3>
        <p
          v-set-class-on-scroll-vertical="'bounceIn'"
          class="animated invisible"
        >
          <img src="../assets/images/bmbf.svg"><br>
          Gefördert mit Mitteln des Bundesministeriums für Bildung und Forschung.
        </p>
      </div>
    </div>

    <div
      id="legal"
      class="container"
    >
      <h3>Haftungsausschluss</h3>
      <p>
        <strong>Keine Abmahnung ohne vorherigen Kontakt!</strong><br>
      </p>
      <h4>1. Haftungsbeschränkung</h4>
      <p>
        Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.<br>
      </p>
      <h4>2. Externe Links</h4>
      <p>
        Diese Website kann Verknüpfungen oder Inhalte von bzw. zu Websites Dritter ( &quot;externe Links&quot; ) enthalten. Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle dieser externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht. Diese Adressen können sie uns, wenn wir sie nicht bemerken, hier mitteilen (Thema Webmaster).<br>
      </p>
      <h4>3. Urheber- und Leistungsschutzrechte</h4>
      <p>
        Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.<br>
        Außerdem ist die Darstellung dieser Website in fremden Frames (wie z.B. iframes) nur mit schriftlicher Erlaubnis zulässig.<br>
      </p>
      <h4>4. Besondere Hinweise</h4>
      <p>
        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Nummern 1. bis 4. abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen. Für alle externen Dienste (wie z. B. google maps) gelten die Bedingungen der jeweiligen Betreiber des Dienstes. Diese iframes sind als externe Links zu betrachten und werden auch als solche behandelt.
      </p>

      <h2>Datenschutzerklärung</h2>
      <h3>Verantwortliche</h3>
      <ul>
        <li>Firma: Gesellschaft für interkulturelles Zusammenleben gGmbH.</li>
        <li>Adresse: Reformationsplatz 2, 13597 Berlin</li>
        <li>Geschäftsführerin: Britta Marschke</li>
        <li>Handesregisternummer HRB 200872 B</li>
        <li>Telefon: +49 30 / 513 0 100 00</li>
        <li>E-Mail: info [at] giz.berlin</li>
      </ul>

      <h3>Datenschutzbeauftragter</h3>
      <p>
        Unser Datenschutzbeauftragter ist unter der Adresse datenschutz [at] giz.berlin zu erreichen.
      </p>

      <h3>Datenschutzerklärung</h3>
      <p>Stand: 11.11.2018</p>

      <h4>Grundsätzliche Angaben zur Datenverarbeitung und Rechtsgrundlagen</h4>
      <p>
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte (nachfolgend gemeinsam bezeichnet als „Onlineangebot“ oder „Website“) auf. Die Datenschutzerklärung gilt unabhängig von den verwendeten Domains, Systemen, Plattformen und Geräten (z.B. Desktop oder Mobile) auf denen das Onlineangebot ausgeführt wird.
      </p>

      <p>
        Die verwendeten Begrifflichkeiten, wie z.B. „personenbezogene Daten“ oder deren „Verarbeitung“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
      </p>

      <p>
        Zu den im Rahmen dieses Onlineangebotes verarbeiteten personenbezogenen Daten der Nutzer gehören Bestandsdaten (z.B., Namen und Adressen von Kunden), Vertragsdaten (z.B., in Anspruch genommene Leistungen, Namen von Sachbearbeitern, Zahlungsinformationen), Nutzungsdaten (z.B., die besuchten Webseiten unseres Onlineangebotes, Interesse an unseren Produkten) und Inhaltsdaten (z.B., Eingaben im Kontaktformular).
      </p>

      <p>
        Der Begriff „Nutzer“ umfasst alle Kategorien von der Datenverarbeitung betroffener Personen. Zu ihnen gehören unsere Geschäftspartner, Kunden, Interessenten und sonstige Besucher unseres Onlineangebotes. Die verwendeten Begrifflichkeiten, wie z.B. „Nutzer“ sind geschlechtsneutral zu verstehen.
      </p>

      <p>
        Wir verarbeiten personenbezogene Daten der Nutzer nur unter Einhaltung der einschlägigen Datenschutzbestimmungen. Das bedeutet, die Daten der Nutzer werden nur bei Vorliegen einer gesetzlichen Erlaubnis verarbeitet. D.h., insbesondere wenn die Datenverarbeitung zur Erbringung unserer vertraglichen Leistungen (z.B. Bearbeitung von Aufträgen) sowie Online-Services erforderlich, bzw. gesetzlich vorgeschrieben ist, eine Einwilligung der Nutzer vorliegt, als auch aufgrund unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb und Sicherheit unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO, insbesondere bei der Reichweitenmessung, Erstellung von Profilen zu Werbe- und Marketingzwecken sowie Erhebung von Zugriffsdaten und Einsatz der Dienste von Drittanbietern.
      </p>

      <p>
        Wir weisen darauf hin, dass die Rechtsgrundlage der Einwilligungen Art. 6 Abs. 1 lit. a. und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen Art. 6 Abs. 1 lit. b. DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen Art. 6 Abs. 1 lit. c. DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen Art. 6 Abs. 1 lit. f. DSGVO ist.
      </p>

      <h4>Sicherheitsmaßnahmen</h4>
      <p>
        Wir treffen organisatorische, vertragliche und technische Sicherheitsmaßnahmen entsprechend dem Stand der Technik, um sicherzustellen, dass die Vorschriften der Datenschutzgesetze eingehalten werden und um damit die durch uns verarbeiteten Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen.
      </p>

      <p>
        Zu den Sicherheitsmaßnahmen gehört insbesondere die größtenteils verschlüsselte Übertragung von Daten zwischen Ihrem Browser und unserem Server.
      </p>

      <h4>Weitergabe von Daten an Dritte und Drittanbieter</h4>
      <p>
        Eine Weitergabe von Daten an Dritte erfolgt nur im Rahmen der gesetzlichen Vorgaben. Wir geben die Daten der Nutzer an Dritte nur dann weiter, wenn dies z.B. auf Grundlage des Art. 6 Abs. 1 lit. b) DSGVO für Vertragszwecke erforderlich ist oder auf Grundlage berechtigter Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO an wirtschaftlichem und effektivem Betrieb unseres Geschäftsbetriebes.
      </p>

      <p>
        Sofern wir Subunternehmer einsetzen, um unsere Leistungen bereitzustellen, ergreifen wir geeignete rechtliche Vorkehrungen sowie entsprechende technische und organisatorische Maßnahmen, um für den Schutz der personenbezogenen Daten gemäß den einschlägigen gesetzlichen Vorschriften zu sorgen.
      </p>

      <p>
        Sofern im Rahmen dieser Datenschutzerklärung Inhalte, Werkzeuge oder sonstige Mittel von anderen Anbietern (nachfolgend gemeinsam bezeichnet als „Drittanbieter“) eingesetzt werden und deren genannter Sitz sich in einem Drittland befindet, ist davon auszugehen, dass ein Datentransfer in die Sitzstaaten der Drittanbieter stattfindet. Als Drittstaaten sind Länder zu verstehen, in denen die DSGVO kein unmittelbar geltendes Recht ist, d.h. grundsätzlich Länder außerhalb der EU, bzw. des Europäischen Wirtschaftsraums. Die Übermittlung von Daten in Drittstaaten erfolgt entweder, wenn ein angemessenes Datenschutzniveau, eine Einwilligung der Nutzer oder sonst eine gesetzliche Erlaubnis vorliegt.
      </p>

      <h4>Erbringung vertraglicher Leistungen</h4>
      <p>
        Wir verarbeiten Bestandsdaten (z.B., Namen und Adressen sowie Kontaktdaten von Nutzern), Vertragsdaten (z.B., in Anspruch genommene Leistungen, Namen von Kontaktpersonen, Zahlungsinformationen) zwecks Erfüllung unserer vertraglichen Verpflichtungen und Serviceleistungen gem. Art. 6 Abs. 1 lit b. DSGVO.
      </p>

      <p>
        Nutzer können optional ein Nutzerkonto anlegen. Im Rahmen der Registrierung, werden die erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die Nutzerkonten sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto gelöscht, vorbehaltlich deren Aufbewahrung ist aus handels- oder steuerrechtlichen Gründen entspr. Art. 6 Abs. 1 lit. c DSGVO notwendig. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherten Daten des Nutzers unwiederbringlich zu löschen.
      </p>

      <p>
        Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme unserer Onlinedienste, speichern wird die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht hierzu besteht eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.
      </p>

      <p>
        Wir verarbeiten Nutzungsdaten (z.B., die besuchten Webseiten unseres Onlineangebotes, Interesse an unseren Produkten) und Inhaltsdaten (z.B., Eingaben im Kontaktformular oder Nutzerprofil) für Werbezwecke in einem Nutzerprofil.
      </p>

      <h4>Kontaktaufnahme</h4>
      <p>
        Bei der Kontaktaufnahme mit uns (per Kontaktformular oder E-Mail) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet.
      </p>

      <h4>Erhebung von Zugriffsdaten und Logfiles</h4>
      <p>
        Wir erheben auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
      </p>

      <p>
        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
      </p>

      <h4>Cookies &amp; Reichweitenmessung</h4>
      <p>
        Cookies sind Informationen, die von unserem Webserver oder Webservern Dritter an die Web-Browser der Nutzer übertragen und dort für einen späteren Abruf gespeichert werden. Bei Cookies kann es sich um kleine Dateien oder sonstige Arten der Informationsspeicherung handeln.
      </p>

      <p>
        Wir verwenden „Session-Cookies“, die nur für die Zeitdauer des aktuellen Besuchs auf unserer Onlinepräsenz abgelegt werden (z.B. um die Speicherung Ihres Login-Status oder die Warenkorbfunktion und somit die Nutzung unseres Onlineangebotes überhaupt ermöglichen zu können). In einem Session-Cookie wird eine zufällig erzeugte eindeutige Identifikationsnummer abgelegt, eine sogenannte Session-ID. Außerdem enthält ein Cookie die Angabe über seine Herkunft und die Speicherfrist. Diese Cookies können keine anderen Daten speichern. Session-Cookies werden gelöscht, wenn Sie die Nutzung unseres Onlineangebotes beendet haben und sich z.B. ausloggen oder den Browser schließen.
      </p>

      <p>
        Wir verwenden Cookies darüber hinaus für einige persönliche Einstellungen des Nutzers.
      </p>

      <p>
        Über den Einsatz von Cookies im Rahmen pseudonymer Reichweitenmessung werden die Nutzer im Rahmen dieser Datenschutzerklärung informiert.
      </p>

      <p>
        Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.
      </p>

      <p>
        Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, über die Deaktivierungsseite der <a href="http://optout.networkadvertising.org/">
          Netzwerkwerbeinitiative
        </a> und zusätzlich die US-amerikanische <a href="http://www.aboutads.info/choices">
          Webseite
        </a>oder die europäische <a href="http://www.youronlinechoices.com/uk/your-ad-choices/">
          Webseite
        </a> widersprechen.
      </p>

      <h4>Reichweitenanalyse mit Matomo (ehemals PIWIK)</h4>
      <p>
        Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Matomo, eine Open-Source-Software zur statistischen Auswertung der Nutzerzugriffe. Die IP-Adresse der Nutzer wird gekürzt, bevor sie gespeichert wird. Matomo verwendet jedoch Cookies, die auf dem Computer der Nutzer gespeichert werden und die eine Analyse der Benutzung dieses Onlineangebotes durch die Nutzer ermöglichen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.
      </p>

      <p>
        Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieses Onlineangebotes werden auf unserem Server gespeichert.
      </p>

      <iframe
        style="border: 0; height: 200px; width: 600px;"
        src="https://piwik.giz.berlin/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily="
      />

      <h4>Einbindung von Diensten und Inhalten Dritter</h4>
      <p>
        Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”). Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden können.
      </p>

      <h4>Rechte der Nutzer</h4>
      <p>
        Nutzer haben das Recht, auf Antrag unentgeltlich Auskunft zu erhalten über die personenbezogenen Daten, die von uns über sie gespeichert wurden.
      </p>

      <p>
        Zusätzlich haben die Nutzer das Recht auf Berichtigung unrichtiger Daten, Einschränkung der Verarbeitung und Löschung ihrer personenbezogenen Daten, sofern zutreffend, Ihre Rechte auf Datenportabilität geltend zu machen und im Fall der Annahme einer unrechtmäßigen Datenverarbeitung, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
      </p>

      <p>
        Ebenso können Nutzer Einwilligungen, grundsätzlich mit Auswirkung für die Zukunft, widerrufen.
      </p>

      <h4>Löschung von Daten</h4>
      <p>
        Die bei uns gespeicherten Daten werden gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten der Nutzer nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten der Nutzer, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
      </p>

      <p>
        Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung für 6 Jahre gemäß § 257 Abs. 1 HGB (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe, Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und Geschäftsbriefe, Für Besteuerung relevante Unterlagen, etc.).
      </p>

      <h4>Widerspruchsrecht</h4>
      <p>
        Nutzer können der künftigen Verarbeitung ihrer personenbezogenen Daten entsprechend den gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
      </p>

      <h4>Änderungen der Datenschutzerklärung</h4>
      <p>
        Wir behalten uns vor, die Datenschutzerklärung zu ändern, um sie an geänderte Rechtslagen, oder bei Änderungen des Dienstes sowie der Datenverarbeitung anzupassen. Dies gilt jedoch nur im Hinblick auf Erklärungen zur Datenverarbeitung. Sofern Einwilligungen der Nutzer erforderlich sind oder Bestandteile der Datenschutzerklärung Regelungen des Vertragsverhältnisses mit den Nutzern enthalten, erfolgen die Änderungen nur mit Zustimmung der Nutzer.
      </p>

      <p>
        Die Nutzer werden gebeten sich regelmäßig über den Inhalt der Datenschutzerklärung zu informieren.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Impress'
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';


  #fund {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    p {
      text-align: center;

      img {
        max-width: 80%;
        max-height: 350px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
</style>
